import React from 'react';
import ReactDOM from 'react-dom';
import HomePage from '../src/modules/Home'

document.addEventListener('DOMContentLoaded', () => {
    const node = document.getElementById('account_react');
    const data = JSON.parse(node.getAttribute('data')) || {};
    const dataPoll = parseInt(node.getAttribute('data-poll')) || null;

    let divToBeAppended = document.createElement('div');
    divToBeAppended.id = "root";

    ReactDOM.render(
        <HomePage userData={data} dataPoll={dataPoll}/>,
        document.body.appendChild(divToBeAppended),
    );
});
