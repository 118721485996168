import React from "react";
import PagerDutyLogo from "../../../../assets/images/pager-duty.svg";
import UpworkLogo from "../../../../assets/images/upwork.svg";
import RocketLawyerLogo from "../../../../assets/images/rocketlawyer.svg";
import XeroxLogo from "../../../../assets/images/xerox.svg";
import ApplauseLogo from "../../../../assets/images/applause.svg";
import UdemyLogo from "../../../../assets/images/udemy.svg";
import TrimbleLogo from "../../../../assets/images/trimble.png";
import UberLogo from "../../../../assets/images/uber.png";
import UCBerekeleyLogo from "../../../../assets/images/uc_berkeley.png";
import AirgasLogo from "../../../../assets/images/airgas.png";
import Alianzlogo from "../../../../assets/images/alianz.png";
import UnileverLogo from "../../../../assets/images/unilever.svg";
import DataDogLogo from "../../../../assets/images/datadog.svg";
import LorealLogo from "../../../../assets/images/loreal.png";
import GsdkLogo from "../../../../assets/images/gsdk.png";
import HelloFresh from "../../../../assets/images/HelloFresh.png";

const OurClients = () => {
    return (
      <div className="our-clients__block position-relative">
        <div className="header__block d-flex justify-content-center">
          Supercharge Your Pipeline
        </div>
        <div className="sub-header__block d-flex justify-content-center">
          <center>
            {/*Trusted by hundreds of sellers at fast-growing companies*/}
            10K + deals generated-your next deal starts here!
          </center>
        </div>
        <div className="scroller-container">
          <div className="clients__block ml-0 mr-0 d-flex">
            {/*<div>*/}
            {/*  <img src={PagerDutyLogo} alt="Pager Duty Logo" />*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*  <img src={UpworkLogo} alt="Upwork Logo" />*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*  <img src={RocketLawyerLogo} alt="Rocket Lawyer Logo" />*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*  <img src={XeroxLogo} alt="Xeros Logo" />*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*  <img src={ApplauseLogo} alt="Applause Logo" />*/}
            {/*</div>*/}
            {/*<div className="mt-3 d-flex">*/}
            {/*  <img src={UdemyLogo} alt="Udemy Logo" />*/}
            {/*</div>*/}

            <div>
              <img
                src={TrimbleLogo}
                alt="Trimble Logo"
                style={{ height: "20px" }}
              />
            </div>
            <div>
              <img src={UberLogo} alt="Uber Logo" style={{ height: "20px" }} />
            </div>
            <div>
              <img
                src={UCBerekeleyLogo}
                alt="UC Berkeley Logo"
                style={{ height: "20px" }}
              />
            </div>
            <div>
              <img
                src={AirgasLogo}
                alt="Airgas Logo"
                style={{ height: "20px" }}
              />
            </div>
            <div>
              <img
                src={Alianzlogo}
                alt="Alianz Logo"
                style={{ height: "20px" }}
              />
            </div>
            <div>
              <img
                src={UnileverLogo}
                alt="Unilever Logo"
                style={{ height: "20px" }}
              />
            </div>
            <div>
              <img
                src={DataDogLogo}
                alt="Datadog Logo"
                style={{ height: "20px" }}
              />
            </div>
            <div>
              <img
                src={LorealLogo}
                alt="L'Oréal Logo"
                style={{ height: "20px" }}
              />
            </div>
            <div>
              <img src={GsdkLogo} alt="GSDK Logo" style={{ height: "20px" }} />
            </div>
            <div>
              <img
                src={HelloFresh}
                alt="HelloFresh Logo"
                style={{ height: "20px" }}
              />
            </div>
          </div>
          <div className="clients__block ml-0 mr-0 d-flex">
            {/*<div>*/}
            {/*  <img src={PagerDutyLogo} alt="Pager Duty Logo" />*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*  <img src={UpworkLogo} alt="Upwork Logo" />*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*  <img src={RocketLawyerLogo} alt="Rocket Lawyer Logo" />*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*  <img src={XeroxLogo} alt="Xeros Logo" />*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*  <img src={ApplauseLogo} alt="Applause Logo" />*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*  <img src={UdemyLogo} alt="Udemy Logo" />*/}
            {/*</div>*/}
            <div>
              <img
                src={TrimbleLogo}
                alt="Trimble Logo"
                style={{ height: "20px" }}
              />
            </div>
            <div>
              <img src={UberLogo} alt="Uber Logo" style={{ height: "20px" }} />
            </div>
            <div>
              <img
                src={UCBerekeleyLogo}
                alt="UC Berkeley Logo"
                style={{ height: "20px" }}
              />
            </div>
            <div>
              <img
                src={AirgasLogo}
                alt="Airgas Logo"
                style={{ height: "20px" }}
              />
            </div>
            <div>
              <img
                src={Alianzlogo}
                alt="Alianz Logo"
                style={{ height: "20px" }}
              />
            </div>
            <div>
              <img
                src={UnileverLogo}
                alt="Unilever Logo"
                style={{ height: "20px" }}
              />
            </div>
            <div>
              <img
                src={DataDogLogo}
                alt="Datadog Logo"
                style={{ height: "20px" }}
              />
            </div>
            <div>
              <img
                src={LorealLogo}
                alt="L'Oréal Logo"
                style={{ height: "20px" }}
              />
            </div>
            <div>
              <img src={GsdkLogo} alt="GSDK Logo" style={{ height: "20px" }} />
            </div>
            <div>
              <img
                src={HelloFresh}
                alt="HelloFresh Logo"
                style={{ height: "20px" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
}

export default OurClients;