import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import "../assets/styles/components/header.scss";

const Header = ({
  userData,
  showSearchBar,
  campaignManager,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const dropdownMenu = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  const toggleShow = function (e) {
    setShowMenu(!showMenu);
  };

  const setIsSignedInDefault = () => {
    if (!userData || Object.keys(userData).length === 0) return false;
    else return true;
  };

  const [isSignedIn] = useState(setIsSignedInDefault());

  useEffect(() => {
    const handleScroll = () => {
      let thresholdScroll = isMobileView? 70 : 150;
      setScrolled(window.scrollY > thresholdScroll);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  })

  return (
    <header
      // className={"site-header" + (isSignedIn ? " mobile-view-none" : "")}
      className={"site-header" + (scrolled ? " scrolled" : "")}
      id="header"
    >
      <div className="container nav-bar__container">
        <nav className="js-mega-menu navbar u-header__navbar navbar-expand-md hs-menu-initialized hs-menu-horizontal">
          <a className="navbar-brand" href="/" aria-label="Accountstory Logo">
            {scrolled &&
                <svg xmlns="http://www.w3.org/2000/svg" width="117" height="14" viewBox="0 0 117 14" fill="none">
                  <path
                      d="M87.3445 3.79937V5.88374H81.3193V3.79937H87.3445ZM82.6872 1.40234H85.462V10.7299C85.462 10.9861 85.5011 11.1859 85.5793 11.3292C85.6574 11.4681 85.766 11.5658 85.9049 11.6223C86.0482 11.6787 86.2133 11.7069 86.4 11.7069C86.5303 11.7069 86.6605 11.6961 86.7908 11.6744C86.9211 11.6483 87.021 11.6288 87.0904 11.6158L87.5268 13.6806C87.3879 13.724 87.1925 13.7739 86.9406 13.8304C86.6888 13.8912 86.3826 13.9281 86.0222 13.9411C85.3535 13.9672 84.7672 13.8782 84.2635 13.6741C83.7641 13.47 83.3755 13.153 83.0976 12.7231C82.8196 12.2932 82.6829 11.7504 82.6872 11.0947V1.40234Z"
                      fill="#232F5E"/>
                  <path
                      d="M75.2954 13.9986C74.2836 13.9986 73.4086 13.7837 72.6704 13.3538C71.9365 12.9195 71.3699 12.3159 70.9704 11.543C70.5708 10.7657 70.3711 9.86463 70.3711 8.83981C70.3711 7.80631 70.5708 6.90308 70.9704 6.13013C71.3699 5.35283 71.9365 4.74923 72.6704 4.31933C73.4086 3.88509 74.2836 3.66797 75.2954 3.66797C76.3072 3.66797 77.18 3.88509 77.9139 4.31933C78.6521 4.74923 79.221 5.35283 79.6205 6.13013C80.02 6.90308 80.2197 7.80631 80.2197 8.83981C80.2197 9.86463 80.02 10.7657 79.6205 11.543C79.221 12.3159 78.6521 12.9195 77.9139 13.3538C77.18 13.7837 76.3072 13.9986 75.2954 13.9986ZM75.3084 11.8491C75.7687 11.8491 76.153 11.7188 76.4614 11.4583C76.7697 11.1934 77.002 10.833 77.1583 10.377C77.319 9.92108 77.3993 9.40216 77.3993 8.82027C77.3993 8.23838 77.319 7.71946 77.1583 7.26351C77.002 6.80755 76.7697 6.44713 76.4614 6.18224C76.153 5.91735 75.7687 5.78491 75.3084 5.78491C74.8438 5.78491 74.453 5.91735 74.136 6.18224C73.8233 6.44713 73.5867 6.80755 73.426 7.26351C73.2697 7.71946 73.1915 8.23838 73.1915 8.82027C73.1915 9.40216 73.2697 9.92108 73.426 10.377C73.5867 10.833 73.8233 11.1934 74.136 11.4583C74.453 11.7188 74.8438 11.8491 75.3084 11.8491Z"
                      fill="#232F5E"/>
                  <path
                      d="M58.7002 13.8048V0.464844H64.0414C65.0228 0.464844 65.8413 0.610316 66.497 0.901259C67.1527 1.1922 67.6456 1.59605 67.9756 2.1128C68.3057 2.62521 68.4707 3.21578 68.4707 3.88451C68.4707 4.40561 68.3665 4.86373 68.158 5.25889C67.9496 5.64971 67.663 5.97105 67.2982 6.22292C66.9378 6.47043 66.5253 6.6463 66.0606 6.75052V6.88079C66.5687 6.90251 67.0442 7.04581 67.4871 7.3107C67.9344 7.57558 68.297 7.94686 68.5749 8.42453C68.8528 8.89786 68.9918 9.46237 68.9918 10.1181C68.9918 10.8259 68.8159 11.4577 68.4642 12.0136C68.1168 12.565 67.6022 13.0015 66.9204 13.3228C66.2387 13.6441 65.3984 13.8048 64.3996 13.8048H58.7002ZM61.5206 11.499H63.8199C64.6059 11.499 65.1791 11.3492 65.5395 11.0495C65.9 10.7456 66.0802 10.3417 66.0802 9.83799C66.0802 9.46889 65.9911 9.1432 65.8131 8.86095C65.6351 8.57869 65.381 8.35722 65.051 8.19655C64.7253 8.03588 64.3367 7.95555 63.8851 7.95555H61.5206V11.499ZM61.5206 6.04705H63.6115C63.998 6.04705 64.341 5.97974 64.6406 5.84512C64.9446 5.70617 65.1834 5.51076 65.3571 5.25889C65.5352 5.00703 65.6242 4.70523 65.6242 4.3535C65.6242 3.87149 65.4527 3.48284 65.1096 3.18755C64.7709 2.89227 64.2889 2.74462 63.6636 2.74462H61.5206V6.04705Z"
                      fill="#232F5E"/>
                  <path
                      d="M52.2437 13.9986C51.2145 13.9986 50.3287 13.7902 49.5861 13.3733C48.8479 12.9521 48.279 12.3572 47.8795 11.5886C47.48 10.8156 47.2803 9.90154 47.2803 8.84632C47.2803 7.81717 47.48 6.91394 47.8795 6.13664C48.279 5.35935 48.8414 4.75358 49.5666 4.31933C50.2961 3.88509 51.1516 3.66797 52.1329 3.66797C52.793 3.66797 53.4075 3.77436 53.9763 3.98714C54.5495 4.19557 55.0489 4.5104 55.4745 4.93162C55.9044 5.35283 56.2387 5.88261 56.4776 6.52095C56.7164 7.15494 56.8358 7.8975 56.8358 8.74862V9.51072H48.3876V7.79111H54.2238C54.2238 7.39161 54.137 7.0377 53.9633 6.72939C53.7896 6.42107 53.5486 6.18007 53.2403 6.00637C52.9363 5.82833 52.5824 5.73931 52.1785 5.73931C51.7573 5.73931 51.3839 5.83702 51.0582 6.03242C50.7369 6.22349 50.485 6.48187 50.3026 6.80755C50.1202 7.12889 50.0269 7.48714 50.0225 7.8823V9.51723C50.0225 10.0123 50.1137 10.44 50.2961 10.8004C50.4828 11.1608 50.7455 11.4388 51.0842 11.6342C51.423 11.8296 51.8246 11.9273 52.2893 11.9273C52.5976 11.9273 52.8798 11.8839 53.136 11.797C53.3923 11.7102 53.6115 11.5799 53.7939 11.4062C53.9763 11.2325 54.1153 11.0197 54.2108 10.7679L56.7772 10.9372C56.6469 11.5538 56.3798 12.0923 55.976 12.5526C55.5765 13.0086 55.0597 13.3646 54.4258 13.6208C53.7961 13.8727 53.0687 13.9986 52.2437 13.9986Z"
                      fill="#232F5E"/>
                  <path d="M45.4623 0.464844V13.8048H42.6875V0.464844H45.4623Z" fill="#232F5E"/>
                  <path
                      d="M40.6648 3.79937V5.88374H34.6396V3.79937H40.6648ZM36.0075 1.40234H38.7823V10.7299C38.7823 10.9861 38.8214 11.1859 38.8996 11.3292C38.9777 11.4681 39.0863 11.5658 39.2253 11.6223C39.3686 11.6787 39.5336 11.7069 39.7203 11.7069C39.8506 11.7069 39.9808 11.6961 40.1111 11.6744C40.2414 11.6483 40.3413 11.6288 40.4107 11.6158L40.8472 13.6806C40.7082 13.724 40.5128 13.7739 40.2609 13.8304C40.0091 13.8912 39.7029 13.9281 39.3425 13.9411C38.6738 13.9672 38.0875 13.8782 37.5838 13.6741C37.0844 13.47 36.6958 13.153 36.4179 12.7231C36.14 12.2932 36.0032 11.7504 36.0075 11.0947V1.40234Z"
                      fill="#232F5E"/>
                  <path
                      d="M33.368 6.65122L30.8276 6.80755C30.7842 6.59043 30.6909 6.39502 30.5476 6.22132C30.4043 6.04328 30.2154 5.90215 29.9809 5.79793C29.7507 5.68937 29.475 5.63509 29.1536 5.63509C28.7237 5.63509 28.3611 5.72628 28.0659 5.90867C27.7706 6.08671 27.6229 6.32554 27.6229 6.62517C27.6229 6.864 27.7185 7.06592 27.9095 7.23094C28.1006 7.39595 28.4284 7.52839 28.8931 7.62827L30.7039 7.99304C31.6766 8.19279 32.4018 8.51413 32.8794 8.95706C33.3571 9.39998 33.5959 9.98187 33.5959 10.7027C33.5959 11.3584 33.4027 11.9338 33.0162 12.4288C32.6341 12.9239 32.1087 13.3103 31.4399 13.5883C30.7755 13.8618 30.0091 13.9986 29.1406 13.9986C27.8162 13.9986 26.7609 13.7229 25.975 13.1714C25.1933 12.6156 24.7352 11.86 24.6006 10.9046L27.3298 10.7613C27.4123 11.1652 27.6121 11.4735 27.9291 11.6863C28.2461 11.8947 28.6521 11.9989 29.1471 11.9989C29.6335 11.9989 30.0243 11.9056 30.3196 11.7188C30.6192 11.5278 30.7712 11.2824 30.7755 10.9828C30.7712 10.7309 30.6648 10.5247 30.4564 10.364C30.2479 10.199 29.9266 10.0731 29.4923 9.98622L27.7597 9.64099C26.7827 9.44558 26.0553 9.10687 25.5776 8.62486C25.1043 8.14285 24.8676 7.52839 24.8676 6.7815C24.8676 6.13881 25.0413 5.58515 25.3887 5.12051C25.7405 4.65587 26.2333 4.29762 26.8673 4.04576C27.5057 3.7939 28.2526 3.66797 29.108 3.66797C30.3717 3.66797 31.3661 3.93503 32.0913 4.46915C32.8208 5.00327 33.2464 5.73063 33.368 6.65122Z"
                      fill="#232F5E"/>
                  <path
                      d="M20.0478 9.54583V3.80078H22.8226V13.8058H20.1585V11.9884H20.0543C19.8285 12.5747 19.4529 13.0458 18.9274 13.4019C18.4063 13.758 17.7702 13.936 17.0189 13.936C16.3502 13.936 15.7618 13.784 15.2537 13.4801C14.7457 13.1761 14.3483 12.744 14.0617 12.1839C13.7795 11.6237 13.6362 10.9528 13.6318 10.1711V3.80078H16.4067V9.6761C16.411 10.2667 16.5695 10.7335 16.8821 11.0765C17.1948 11.4196 17.6138 11.5911 18.1393 11.5911C18.4737 11.5911 18.7863 11.5151 19.0773 11.3631C19.3682 11.2068 19.6027 10.9767 19.7807 10.6727C19.9631 10.3687 20.0521 9.9931 20.0478 9.54583Z"
                      fill="#232F5E"/>
                  <path
                      d="M0 13.8048V0.464844H2.82041V5.96888H8.54591V0.464844H11.3598V13.8048H8.54591V8.29426H2.82041V13.8048H0Z"
                      fill="#232F5E"/>
                  <circle cx="93.6963" cy="6.9805" r="2.18363" fill="#232F5E"/>
                  <path d="M116.685 0V13.4582H113.031V0H116.685Z" fill="#232F5E"/>
                  <path
                      d="M101.798 13.4582H97.8555L102.298 0H107.292L111.734 13.4582H107.791L104.847 3.70626H104.742L101.798 13.4582ZM101.062 8.14851H108.475V10.8822H101.062V8.14851Z"
                      fill="#377DFF"/>
                </svg>
            }
          </a>
          {/* {showSearchBar && <SearchBar />} */}
          {/* {isSignedIn && showSearchBar ? ( */}
          {isSignedIn && false ? (
            <></>
          ) : (
            <div>
              {(!isSignedIn && (
                // <a className="mobile-view" href="/users/sign_up?plan_type=free">
                <a className="mobile-view" href="#">
                  <div className="btn btn-sm btn-primary u-btn-primary transition-3d-hover join-now__btn mr-3">
                    Get Started
                  </div>
                </a>
              )) ||
                (isSignedIn && (
                  <a
                    className="btn btn-sm btn-link text-secondary mobile-view"
                    href="/account/dashboard"
                  >
                    <img src="/assets/img/profile_icon.svg" height="18" />
                  </a>
                ))}
              <button
                type="button"
                className={`navbar-toggler btn u-hamburger${
                  showMenu ? "" : " collapsed"
                }`}
                aria-label="Toggle navigation"
                aria-expanded={showMenu}
                aria-controls="navBar"
                data-toggle="collapse"
                data-target="#navBar"
                onClick={toggleShow}
              >
                <span id="hamburgerTrigger" className="u-hamburger__box">
                  <span className="u-hamburger__inner"></span>
                </span>
              </button>
            </div>
          )}

          {/* {isSignedIn && showSearchBar ? (
            <></>
          ) : ( */}
            <CSSTransition
              in={showMenu}
              timeout={350}
              classNames="display"
              onEnter={() => {
                dropdownMenu.current.classList.remove("collapse");
              }}
              onExited={() => {
                dropdownMenu.current.classList.add("collapse");
                dropdownMenu.current.classList.remove("display-exit-done");
              }}
            >
              <div
                style={{ justifyContent: "flex-end" }}
                id="navBar"
                className="navbar-collapse py-0 collapse"
                ref={dropdownMenu}
              >
                <ul className="navbar-nav u-header__navbar-nav ml-lg-auto">
                  {/* <!-- Button Write a review --> */}

                  {/*<li className="nav-item u-header__nav-item hs-mega-menu-opened  my-3">*/}
                  {/*  <div data-event="">*/}
                  {/*    <div className="dropdown show ">*/}
                  {/*      <Dropdown>*/}
                  {/*        <Dropdown.Toggle*/}
                  {/*          variant="btn  btn-sm dropdown-toggle btn-link font-weight-bold customer-stories__nav"*/}
                  {/*          id="productMenuLink"*/}
                  {/*        >*/}
                  {/*          Products*/}
                  {/*          <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                  {/*            <path d="M11.2946 0.999599C10.9053 0.610326 10.2743 0.609982 9.88462 0.99883L6.70633 4.17019C6.31599 4.55968 5.68401 4.55968 5.29366 4.17019L2.11538 0.99883C1.72569 0.609982 1.09466 0.610326 0.705384 0.999598V0.999598C0.315811 1.38917 0.315811 2.02079 0.705385 2.41037L5.29289 6.99788C5.68342 7.3884 6.31658 7.3884 6.70711 6.99788L11.2946 2.41037C11.6842 2.02079 11.6842 1.38917 11.2946 0.999599V0.999599Z" fill="#232F5E"/>*/}
                  {/*          </svg>*/}



                  {/*        </Dropdown.Toggle>*/}
                  {/*        <Dropdown.Menu>*/}
                  {/*          {DropdownItem("/campaign", "Campaign Manager")}*/}
                  {/*          {DropdownItem("/insights", "Account Insights")}*/}
                  {/*          /!*{DropdownItem("/chrome", "Chrome Extension")}*!/*/}
                  {/*        </Dropdown.Menu>*/}
                  {/*      </Dropdown>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</li>*/}

                  {/* <!-- Pricing Button --> */}
                  <li className="nav-item u-header__nav-item hs-mega-menu-opened my-3">
                    <a
                      className="btn btn-sm btn-link font-weight-bold customer-stories__nav"
                      href="#hustle-bot__pricing"
                    >
                      Pricing
                    </a>
                  </li>
                  <li className="nav-item u-header__nav-item hs-mega-menu-opened  my-3">
                    <div data-event="">
                      <div className="dropdown show ">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="btn  btn-sm dropdown-toggle btn-link font-weight-bold customer-stories__nav"
                            id="productMenuLink"
                          >
                            Resources
                            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.2946 0.999599C10.9053 0.610326 10.2743 0.609982 9.88462 0.99883L6.70633 4.17019C6.31599 4.55968 5.68401 4.55968 5.29366 4.17019L2.11538 0.99883C1.72569 0.609982 1.09466 0.610326 0.705384 0.999598V0.999598C0.315811 1.38917 0.315811 2.02079 0.705385 2.41037L5.29289 6.99788C5.68342 7.3884 6.31658 7.3884 6.70711 6.99788L11.2946 2.41037C11.6842 2.02079 11.6842 1.38917 11.2946 0.999599V0.999599Z" fill="#232F5E"/>
                            </svg>

                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {DropdownItem("/customer_stories", "Customer Stories")}
                            {/* {DropdownItem("/insights", "Account Insights")} */}
                            {/*{DropdownItem("/chrome", "Chrome Extension")}*/}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </li>
                  {/* <!-- End Button Write a review --> */}

                  {/* <!-- Button Sign in --> */}
                  <li
                    className="nav-item u-header__nav-item hs-sub-menu-opened my-3 showSignIn"
                    data-event="hover"
                    data-animation-in="slideInUp"
                    data-animation-out="fadeOut"
                    data-position="left"
                  >
                    <a
                      data-action="tapped sign in"
                      data-label="navigation"
                      data-category="homepage"
                      data-overlay-color="#111722"
                      data-modal-target="#signInModal"
                      className="ga btn btn-sm btn-link small headerSignIn font-weight-bold customer-stories__nav"
                      href={`/users/${isSignedIn ? "sign_out" : "sign_in"}`}
                    >
                      {isSignedIn ? "Logout" : "Sign in"}
                    </a>
                  </li>
                  {/* <!-- End Button Sign in --> */}
                  {/* <!-- Button Get Started --> */}
                  <li
                    className="nav-item u-header__nav-item hs-sub-menu-opened my-3 Signup desktop-view"
                    data-animation-in="fadeIn"
                    data-event="hover"
                    data-animation-out="fadeOut"
                  >
                    {(!isSignedIn && (
                      <a
                        className="btn btn-sm btn-primary u-btn-primary transition-3d-hover join-now__btn"
                        // href="/users/sign_up?plan_type=free"
                        href="#"
                      >
                        Get Started
                      </a>
                    )) ||
                      (isSignedIn && (
                        <a
                          className="btn btn-sm  go-to-account__btn pr-3 pl-3 pt-1 pb-1"
                          href="/account/dashboard"
                        >
                           Go to account
                        </a>
                      ))}
                  </li>
                  {/* <!-- End Button Get Started --> */}
                </ul>
              </div>
            </CSSTransition>
          {/* )} */}
        </nav>
      </div>
    </header>
  );

  function DropdownItem(dropDownLocation, dropDownText) {
    return <Dropdown.Item
      className="btn btn-sm btn-link font-weight-bold"
      href={dropDownLocation}
    >
      {dropDownText}
    </Dropdown.Item>;
  }
};

Header.propTypes = {
  userData: PropTypes.object.isRequired,
  showSearchBar: PropTypes.bool,
  campaignManager: PropTypes.bool,
};

export default Header;
