import React from "react";
import MailboxContentImage from "../../../../../assets/images/mailbox-content.png";

const Mailbox = () => {
    return (
        <div style={{
            width: "885px"
        }}>
            <center>
                Our integrated mailbox streamlines your workflow by gathering all responses in one place. AI categorizes
                replies—whether they’re positive responses, objections, out-of-office notices, or auto-replies—while
                pinpointing your top-priority leads.
            </center>

            <div style={{
                marginTop: "27px"
            }}>
                <img src={MailboxContentImage} alt="Messaging Content Image" style={{
                    maxWidth: "100%",
                    borderTopRightRadius: "30px",
                    borderTopLeftRadius: "30px",
                }}/>
            </div>
        </div>
    )
}

export default Mailbox;