import React from "react";
import AiPoweredHyperPersonalizationLogo from "../../../../assets/images/ai-powered-hyper-personalization.svg";
import HyperPersonalizationLogo from "../../../../assets/images/hyper-personalization.svg";
import FiftyMillionContractLogo from "../../../../assets/images/50-million-contract.svg";
import GuaranteedDeliverabilityLogo from "../../../../assets/images/guaranteed-deliverability.svg";
import IntegratedMailboxLogo from "../../../../assets/images/integrated-mailbox.svg";
import ReportingAnalyticsLogo from "../../../../assets/images/reporting-analytics-fingertips.svg";
import PayForResultLogo from "../../../../assets/images/pay-for-results.svg";
import Slider from "react-slick";
import Carousel from 'react-bootstrap/Carousel';

const UnparalledFeatures = () => {

    const featuresSet = [
        {
            title: "AI-Powered Hyper-Personalization",
            image: AiPoweredHyperPersonalizationLogo,
            description: "In todays outreach landscape, personalizing outreach is to stand out in a crowded inbox is critical. Our tool automatically hyper personalizes messages to increase engagement rates rates with your target audience. These messages grab more attention, and increase opens, clicks and replies."
        },
        {
            title: "Global Reach with 200M+ contacts",
            image: FiftyMillionContractLogo,
            description: "With our tool, you gain access to a global dataset of over 275 million contacts. Whether you're targeting executives, decision-makers, or niche professionals, our database empowers your outreach efforts with unmatched precision and scale."
        }, {
            title: "Guaranteed Deliverability",
            image: HyperPersonalizationLogo,
            description: "Save time prospecting. Accountstory identifies the champions, influencers, and decision makers at your target accounts who buy things."
        }, {
            title: "Integrated Mailbox with AI Detection",
            image: IntegratedMailboxLogo,
            description: "Managing outreach can be overwhelming, with multiple tools and accounts leading to missed opportunities. Our integrated mailbox simplifies this by consolidating all responses in one place. AI organizes replies—positive responses, objections, out-of-office messages, and auto-replies—while highlighting high-priority leads so you can focus on the prospects most likely to convert."
        }, {
            title: "Pay for Results",
            image: PayForResultLogo,
            description: "We believe in delivering tangible outcomes, which is why our pricing model is results oriented. Part of what you pay is tied directly to the results we achieve together - our success is your success."
        }, {
            title: "Reporting & Analytics at Your Fingertips",
            image: ReportingAnalyticsLogo,
            description: "Our platform provides real-time insights into every stage of your sending process, so you’re always informed and have full visibility into your campaigns. Monitor opens, clicks, and replies to understand how your audience is interacting with your messages. With out analytics, you’ll never be in the dark about your outreach efforts. You’ll have the insights you need to optimize performance and drive results."
        }
    ]


    return (
        <div className="unparalled-feature-set__block w-100 position-relative">
            <div className="position-absolute w-100" style={{
                top: "0",
                height: "1px",
                background: 'linear-gradient(90deg, rgba(35, 47, 94, 0.00) 0%, #232F5E 37.53%, rgba(35, 47, 94, 0.00) 100%)',
                opacity: 0.3
            }}/>
            <div className="header d-flex">
                Unparalled Feature Set
            </div>
            <div className="unparalled-features__block--desktop ml-0 mr-0">
                {
                    featuresSet.map(feature => {
                        return (
                            <div className="feature">
                                <div className="d-flex">
                                    <div>
                                        <img src={feature.image} alt={feature.title}/>
                                    </div>
                                    <div style={{
                                        marginLeft: "20px"
                                    }}>
                                        <div className="feature-title">
                                            {feature.title}
                                        </div>
                                        <div className="mt-2 feature-description" style={{
                                            color: "rgba(0, 0, 0, 0.64)",
                                            fontSize: "16px",
                                            fontWeight: 400
                                        }}>
                                            {feature.description}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <div className="unparalled-features__block--mobile" style={{
                marginTop: "33px"
            }}>
                <Carousel>
                    {
                        featuresSet.map(feature => {
                            return (
                                <Carousel.Item>
                                    <div className="feature">
                                        <div>
                                            <div>
                                                <img src={feature.image} alt={feature.title} style={{
                                                    height: "48px"
                                                }}/>
                                            </div>
                                            <div>
                                                <div className="feature-title" style={{
                                                    marginTop: "15px"
                                                }}>
                                                    {feature.title}
                                                </div>
                                                <div className="mt-2 feature-description" style={{
                                                    color: "rgba(0, 0, 0, 0.64)",
                                                    fontSize: "16px",
                                                    fontWeight: 400
                                                }}>
                                                    {feature.description}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            )
                        })
                    }
                </Carousel>
            </div>
        </div>
    )
}

export default UnparalledFeatures;