import React from  "react";
import MessagingContentImage from "../../../../../assets/images/messaging-content.png";

const Messaging = () => {
    return(
        <div style={{
            width: "885px"
        }}>
            <center>
                We craft messaging tailored specifically to your ideal customer profile, delivering hyper-personalized content that resonates and drives engagement. We make it easy to tweak and adjust the tone to suit your preferences.
            </center>
            <div style={{
                marginTop: "27px"
            }}>
                <img src={MessagingContentImage} alt="Messaging Content Image" style={{
                    maxWidth: "100%",
                    borderTopRightRadius: "30px",
                    borderTopLeftRadius: "30px",
                }}/>
            </div>
        </div>
    )
}

export default Messaging;