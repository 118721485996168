import React from "react";
import ReportingContentImage from "../../../../../assets/images/reporting-content.png";

const Reporting = () => {
    return (
        <div style={{
            width: "885px"
        }}>
            <center>
                Track opens, clicks, and replies to gain clear insights into how your audience engages with your
                messages. Our analytics ensure you’re always informed about the performance of your outreach efforts.
            </center>
            <div style={{
                marginTop: "27px"
            }}>
                <img src={ReportingContentImage} alt="Messaging Content Image" style={{
                    maxWidth: "100%",
                    borderTopRightRadius: "30px",
                    borderTopLeftRadius: "30px",
                }}/>
            </div>
        </div>
    )
}

export default Reporting;