import "regenerator-runtime/runtime.js";
import * as apiCall from '../../axios';
import * as actionTypes from './action-types';

let apiVersion = 'v3';

// Set isCampaignManagerGuest

export const setIsCampaignManagerGuestLoader = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_IS_CAMPAIGN_MANAGER_GUEST_LOADER, payload: data });
}

// Track page api
export const trackCurrentPage = (currentPage) => {
  return apiCall.sendPostRequest(`/api/${apiVersion}/track_page`, { path: currentPage });
}
export const trackEvent = (currentEvent) => {
  return apiCall.sendPostRequest(`/api/${apiVersion}/track_event`, currentEvent);
}

// calendly call scheduled api
export const calendlyCallScheduled = (data) => {
  return apiCall.sendPostRequest(`/api/${apiVersion}/calendly_call_scheduled`, data);
}

// Set toggle edit loader
export const setToggleEditLoader = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_TOGGLE_EDIT_LOADER, payload: data });
}

// Actions
export const setCampaignData = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_CAMPAIGN_DATA, payload: data })
}

// Actions
export const setScrapedCampaignData = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_SCRAPED_CAMPAIGN_DATA, payload: data })
}

export const setEditMode = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_EDIT_MODE, payload: data });
}

export const setCampaignId = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_CAMPAIGN_ID, payload: data });
}

export const setCampaignLoader = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_CAMPAIGN_LOADER, payload: data })
}

export const setTargetAudience = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_TARGET_AUDIENCE, payload: data })
}

export const setSampledProspects = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_SAMPLED_PROSPECTS, payload: data })
}

export const setCreativeAttachments = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_CREATIVE_ATTACHMENTS, payload: data })
}

export const setAudienceAttachments = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_AUDIENCE_ATTACHMENTS, payload: data })
}

export const changeCampaignStatus = (dispatch, newStatus) => {
  dispatch({ type: actionTypes.CHANGE_CAMPAIGN_STATUS, payload: newStatus })
}

export const setIsCampaignEditable = (dispatch, newStatus) => {
  dispatch({ type: actionTypes.SET_IS_CAMPAIGN_EDITABLE, payload: newStatus })
}

export const setIncompleteStatus = (dispatch, incompleteFields) => {
  dispatch({ type: actionTypes.SET_INCOMPLETE_STATUS, payload: incompleteFields })
}

export const setForecastedReachData = (dispatch, forecastedReach) => {
  dispatch({ type: actionTypes.SET_FORECASTED_REACH, payload: forecastedReach })
}

export const updateAudienceSize = (dispatch, updateAudienceSize) => {
  dispatch({ type: actionTypes.UPDATE_AUDIENCE_SIZE, payload: updateAudienceSize })
}

export const resetCampaignData = (dispatch) => {
  dispatch({ type: actionTypes.RESET_CAMPAIGN_DATA, payload: {} });
}

// Campaign Data Requests
export const getCampaings = (data) => {
  return apiCall.sendRequest(`/api/${apiVersion}/campaigns`, data)
}

export const getCampaignLeads = (limit, campaignIds, startDate, endDate, page, per_page) => {
  return apiCall.sendRequest(`/api/${apiVersion}/campaign_leads`, { limit: limit, campaign_ids: campaignIds, start_date: startDate, end_date: endDate, page: page, per_page: per_page })
}

export const getCampaignData = (data) => {
  return apiCall.sendRequest(`/api/${apiVersion}/draft_campaign`, data);
}

// Update campaign meta data
export const updateCampaignMetaData = (data) => {
  return apiCall.sendPostRequest(`/api/${apiVersion}/update_campaign_metadata`, data);
}

// Forecasted Reach Request
export const getForcastedReachData = (data) => {
  return apiCall.sendRequest(`/api/${apiVersion}/forecasted_reach_data`, { campaign_token: data });
}

// Audience Request
export const getTitles = async (searchParams) => {
  let response = await apiCall.sendRequest(`/api/${apiVersion}/search_titles`, searchParams);
  return response.data.result || [];
}

export const getIndustries = async (searchParams) => {
  let response = await apiCall.sendRequest(`/api/${apiVersion}/search_industries`, searchParams);
  return response.data.result || []
}

export const getDepartments = async (searchParams) => {
  let response = await apiCall.sendRequest(`/api/${apiVersion}/search_departments`, searchParams);
  return response.data.result || [];
}

export const getGeographies = async (searchParams) => {
  let response = await apiCall.sendRequest(`/api/${apiVersion}/search_geographies`, searchParams);
  return response.data.result || [];
}

export const getCustomers = async (searchParams) => {
  let response = await apiCall.sendRequest(`/api/${apiVersion}/search_companies`, searchParams);
  return response.data.result || [];
}

export const getCompanySize = () => {
  return apiCall.sendRequest(`/api/${apiVersion}/company_size`, {});
}

export const getTools = () => {
  return apiCall.sendRequest(`/api/${apiVersion}/tools_evaluated`, {});
}

export const getUpdatedForecastedReach = (data) => {
  return apiCall.sendPostRequest(`/api/${apiVersion}/campaign_forecasted_reach`, data);
}

export const sendCampaignData = (data) => {
  return apiCall.sendPostRequest(`/api/${apiVersion}/campaign_audience`, data);
}

// Delivery Method Requests
export const getSDRProfiles = () => {
  return apiCall.sendRequest(`/api/${apiVersion}/campaign_sdr_profiles`, {});
}

export const sendDeliveryMethodData = (data) => {
  return apiCall.sendPostRequest(`/api/${apiVersion}/delivery_method`, data);
}

// Creative Requests
export const sendCreativeData = (data) => {
  return apiCall.sendPostRequest(`/api/${apiVersion}/campaign_creative`, data);
}

export const sendAttachments = (formData) => {
  return apiCall.uploadFile(`/api/${apiVersion}/campaign_attachments`, formData);
}

// Budget
export const activeCampaign = (data) => {
  return apiCall.sendPostRequest(`/api/${apiVersion}/activate_campaign`, data);
}

// Get campaign phases after activation
export const getCampaignPhases = () => {
  return apiCall.sendRequest(`/api/${apiVersion}/campaign_phases`);
}

export const setCampaignPhase = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_CAMPAIGN_PHASE, payload: data });
}

// Campaign Edit Mode
export const getCampaignEditData = (data) => {
  return apiCall.sendPostRequest(`/api/${apiVersion}/campaign_edit_mode`, { campaign_id: data });
}

export const sendCampaignEditData = (data) => {
  return apiCall.sendPostRequest(`/api/${apiVersion}/campaign_edit_mode`, data);
}

export const setDraftCampaignId = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_DRAFT_CAMPAIGN_ID, payload: data });
}

export const setEditedSteps = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_EDITED_STEPS, payload: data });
}

export const discardEditedCampaignChanges = (data) => {
  return apiCall.sendPostRequest(`/api/${apiVersion}/clear_draft_changes`, data);
}

export const applyEditedCampaignChanges = (data) => {
  return apiCall.sendPostRequest(`/api/${apiVersion}/save_draft_changes`, data);
}

export const setDraftCampaignBudget = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_DRAFT_CAMPAIGN_BUDGET, payload: data });
}

export const setDraftCampaignBudgetType = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_DRAFT_CAMPAIGN_BUDGET_TYPE, payload: data });
}

export const setDraftCampaignSuccess = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_DRAFT_CAMPAIGN_SUCCESS, payload: data });
}

export const setDraftCampaignSalesCycleLength = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_DRAFT_CAMPAIGN_SALES_CYCLE_LENGTH, payload: data });
}

export const setDraftCampaignPricePoint = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_DRAFT_CAMPAIGN_PRICE_POINT, payload: data });
}

export const setDraftCampaignCostPerLead = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_DRAFT_CAMPAIGN_COST_PER_LEAD, payload: data });
}

export const deleteAttachment = (data) => {
  return apiCall.sendPostRequest(`/api/${apiVersion}/delete_campaign_attachment`, data);
}

// Campaign manager payment
export const getCampaignTransactions = () => {
  return apiCall.sendRequest(`/api/${apiVersion}/campaign_transactions`);
}

export const createStripeCustomer = (editCardInfo) => {
  return apiCall.sendRequest(`/api/${apiVersion}/stripe_customer`, { edit: editCardInfo })
}

// company insights details
export const getCompanyInsightsDetails = (companyData) => {
  return apiCall.sendRequest(`/api/${apiVersion}/company_insights_details`, companyData)
}

// Get is the user is campaign manager guest or not
export const isCampaignManagerGuest = () => {
  return apiCall.sendRequest(`/api/${apiVersion}/campaign_manager_guest`);
}

// Get is the user is campaign manager guest or not
export const isNewUser = () => {
  return apiCall.sendRequest(`/api/${apiVersion}/new_user`);
}

export const setIsCampaignManagerGuest = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_IS_CAMPAIGN_MANAGER_GUEST, payload: data })
}

// Set if the user is new or not
export const setIsNewUser = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_IS_NEW_USER, payload: data })
}

export const setAttachmentCampaignId = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_ATTACHMENT_CAMPAIGN_ID, payload: data })
}

// Get user data
export const getUserData = () => {
  return apiCall.sendRequest(`/api/${apiVersion}/account_settings_data`)
}
export const fetchUserCompany = (email) => {
  return apiCall.sendPostRequest(`/api/${apiVersion}/fetch_user_company`, { email: email })
}
export const getAutomatedFormAnswers = (data) => {
  return apiCall.sendAwaitPostRequest(`/api/${apiVersion}/get_form_answers`, data)
  // return apiCall.sendPostRequest(`/api/${apiVersion}/get_form_answers`, data)
}

export const setAnalyticsFilterDate = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_ANALYTICS_FILTER_DATE, payload: data });
}

export const setFilterCampaignIds = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_FILTER_CAMPAIGN_IDS, payload: data });
}

// outreach analytics
export const getOutreachAnalytics = (data) => {
  return apiCall.sendRequest(`/api/${apiVersion}/outreach_analytics`, data)
}

// outreach analytics event count
export const getOutreachAnalyticsCount = (data) => {
  return apiCall.sendRequest(`/api/${apiVersion}/outreach_analytics_count`, data)
}

// outreach analytics event count
export const getOutreachAnalyticsViewCopy = () => {
  return apiCall.sendRequest(`/api/${apiVersion}/outreach_analytics_view_copy`)
}

// outreach insights
export const getOutreachInsights = (data) => {
  return apiCall.sendRequest(`/api/${apiVersion}/outreach_insights`, data)
}

export const getActivatedCampaigns = () => {
  return apiCall.sendRequest(`/api/${apiVersion}/activated_campaigns`);
}
export const deleteCampaigns = (campaignIds) => {
  return apiCall.sendPostRequest(`/api/${apiVersion}/delete_campaigns`, { campaign_ids: campaignIds });
}

// generate and send analytics csv
export const sendAnalyticsCsv = (data) => {
  return apiCall.sendRequest(`/api/${apiVersion}/send_analytics_csv`, data);
}

// outreach analytics event count
export const getIsMasqueradeUser = () => {
  return apiCall.sendRequest(`/is_masquerade_user`)
}

// AI Form Answers
export const setAiFormAnswersLoading = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_AI_FORM_ANSWERS_LOADING, payload: data });
}
export const setIsAiFormAnswers = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_IS_AI_FORM_ANSWERS, payload: data });
}
export const setAiAnswersLoading = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_AI_ANSWERS_LOADING, payload: data });
}
export const setAiAnswersError = (dispatch, data) => {
  dispatch({ type: actionTypes.SET_AI_ANSWERS_ERROR, payload: data });
}

// AI Bot actions

export const getAiBotResponse = (user_message) => {
  return apiCall.sendPostRequest(`/api/${apiVersion}/get_ai_bot_answers`, {
    user_message: user_message,
  });
};

export const getAiBotConversation = (user_message) => {
  return apiCall.sendRequest(`/api/${apiVersion}/get_ai_bot_conversation`, {})
}

// if the logged in user is on growth plan
export const getIsGrowthPlanUser = () => {
  return apiCall.sendRequest(`/api/${apiVersion}/is_growth_plan_user`)
}

// if the logged in user is on growth plan
export const startWebsiteCrawling = (website) => {
  return apiCall.sendPostRequest(`/api/${apiVersion}/start_website_crawler`, {
    website: website
  })
}