import React from "react";
import PropTypes from 'prop-types';
import "../../assets/styles/Home/index.scss";
import { Footer } from "../../components/footer";
import Header  from "../../components/header";
import { LandingPageContent } from "./content";
import HustleBotLandingPage from "./HustleBot";

// import mixpanel from "mixpanel-browser";
//
//
// // Near entry of your product, init Mixpanel
// mixpanel.init(window.MIXPANEL_PROJECT_TOKEN || process.env.MIXPANEL_PROJECT_TOKEN, {
//     debug: true,
//     track_pageview: true,
//     persistence: "localStorage",
// });

console.log(window.MIXPANEL_PROJECT_TOKEN);

const HomePage = ({ userData, dataPoll}) => {
  return (
      <React.Fragment>
        <Header userData={userData}/>
        {/*<LandingPageContent*/}
        {/*  dataPoll={dataPoll}*/}
        {/*/>*/}
        {/*<Footer />*/}
        <HustleBotLandingPage />
      </React.Fragment>
  );
};

HomePage.propTypes = {
  userData: PropTypes.object.isRequired,
  dataPoll: PropTypes.number.isRequired,
}

export default HomePage;
