const accountRouteList = {
    dashboard: '/account/dashboard',
    settings: '/account/settings',
    campaign_manager: {
        root: '/account/campaign_manager',
        new: '/account/campaign_manager/new',
        audience: '/account/campaign_manager/:id/audience',
        delivery_method: '/account/campaign_manager/:id/delivery_method',
        new_delivery_method: '/account/campaign_manager/delivery_method',
        creative: '/account/campaign_manager/:id/creative',
        new_creative: '/account/campaign_manager/creative',
        budget_and_launch: '/account/campaign_manager/:id/budget_and_launch',
        new_budget_and_launch: '/account/campaign_manager/budget_and_launch',
        list: '/account/campaign_manager',
        leads: '/account/campaign_manager/leads',
        outReachAnalytics: '/account/campaign_manager/outreach_analytics/:id',
        viewCopy: '/account/campaign_manager/view_copy',
        hustle_bot: {
            root: '/account/campaign_manager/:id/hustle_bot',
            analytics: '/account/campaign_manager/:id/hustle_bot/analytics',
            audience: '/account/campaign_manager/:id/hustle_bot/audience',
            workflow_view: '/account/campaign_manager/:id/hustle_bot/workflow_view',
            mail_box: {
                root: '/account/campaign_manager/:id/hustle_bot/mail_box',
            },
            journey: {
                root: '/account/campaign_manager/:id/hustle_bot/journey',
                journey_details: '/account/campaign_manager/:id/hustle_bot/journey/:delivery_batch_id/:prospect_id'
            },
            settings: '/account/campaign_manager/:id/hustle_bot/settings',
        }
    }
}

export default accountRouteList;