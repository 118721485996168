import React from 'react';
import {ReactTyped} from "react-typed";

// AI Logo Component (Replace the SVG content with your provided AI Logo)
const AILogo = () => (
    <svg width="102" height="104" viewBox="0 0 102 104" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_6894_18181)">
            <circle cx="52" cy="52" r="40" fill="white"/>
        </g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M51.9905 25.3339C45.8713 25.3339 39.7664 27.4075 34.7947 31.5536C31.8023 34.0491 29.4896 37.0855 27.8834 40.4104C27.6605 40.3893 27.4436 40.3413 27.2147 40.3413C23.218 40.3413 20 43.5395 20 47.5116V56.3735C20 60.3456 23.218 63.5438 27.2147 63.5438C27.4436 63.5438 27.6605 63.4945 27.8834 63.4747C28.171 64.0671 28.4686 64.6551 28.8039 65.2328C30.1148 67.5582 33.6172 65.5672 32.2647 63.2652C26.7273 53.7329 28.8719 41.6538 37.3555 34.5788C45.8394 27.5037 58.1622 27.5037 66.646 34.5788C75.1298 41.6538 77.2744 53.7327 71.7369 63.2652L71.6772 63.3639L71.6373 63.4626C71.6373 63.4626 68.9479 69.3757 63.6668 69.3757L56.9819 69.3162C56.3549 68.4064 55.3516 67.7727 54.1536 67.7727H49.8443C47.9122 67.7727 46.3578 69.3227 46.3578 71.243C46.3578 72.4275 47.1141 73.3269 48.8447 73.3269L63.6668 73.3281C71.5917 73.3281 75.0192 65.5957 75.1752 65.2379V65.2168C75.5076 64.6441 75.8001 64.0561 76.0857 63.469C76.3186 63.4901 76.5455 63.5381 76.7853 63.5381C80.782 63.5381 84 60.3399 84 56.3679V47.5059C84 43.5339 80.782 40.3356 76.7853 40.3356C76.5544 40.3356 76.3365 40.3735 76.1116 40.4047C74.5092 37.0796 72.2034 34.0435 69.2106 31.5478C64.239 27.4017 58.1085 25.3281 51.9892 25.3281L51.9905 25.3339Z" fill="#232F5E"/>
        <path d="M38.6055 40.2251C39.827 38.91 41.2249 38.5792 42.7553 38.6813C45.3057 38.8525 48.2268 40.2251 51.3334 40.2251C56.304 40.2251 60.804 36.7189 64.0614 40.2251C67.3187 43.7311 69.3359 48.503 69.3359 53.5849C69.3359 57.4201 68.1881 62.2179 66.2249 62.5727C62.9855 63.1591 57.5233 60.6819 51.3334 60.6819C45.4923 60.6819 40.3029 62.8897 37.0145 62.6452C34.7062 62.4737 33.3359 57.7767 33.3359 53.5849C33.3359 48.503 35.3482 43.7311 38.6055 40.2251Z" fill="#377DFF"/>
        <path d="M45.2776 49.9975C45.277 48.1567 43.7969 46.6648 41.9708 46.6641C40.1448 46.6648 38.6647 48.1567 38.6641 49.9975C38.6647 51.8381 40.1448 53.3301 41.9708 53.3307C43.7969 53.3301 45.277 51.8381 45.2776 49.9975Z" fill="white"/>
        <path d="M63.9974 49.9975C63.9967 48.1567 62.5167 46.6648 60.6905 46.6641C58.8645 46.6648 57.3844 48.1567 57.3837 49.9975C57.3844 51.8381 58.8645 53.3301 60.6905 53.3307C62.5167 53.3301 63.9967 51.8381 63.9974 49.9975Z" fill="white"/>
        <defs>
            <filter id="filter0_d_6894_18181" x="0" y="0" width="104" height="104" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="6"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6894_18181"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6894_18181" result="shape"/>
            </filter>
        </defs>
    </svg>
);

const InfoBoxWithLogo = ({text, dataFetched}) => {
    return (
        <div className="position-fixed w-100 p-4 d-flex justify-content-center" style={{
            bottom: "0px",
            left: "0px",
            zIndex: 100
        }}>
            <div className="ai-info__box d-flex position-relative align-items-center" style={{
                bottom: "20px",
                zIndex: "100"
            }}>
                <div className={"info-box p-2" + (dataFetched? " success": "")} style={{
                    height: "fit-content"
                }}>
                    <ReactTyped strings={[!dataFetched? text : "Done!!"]} typeSpeed={25}/>
                </div>
                <div>
                    <div className={"triangle-right" + (dataFetched? " success": "")}/>
                </div>

                <AILogo/>
            </div>
        </div>
    );
};

export default InfoBoxWithLogo;