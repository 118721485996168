import React from "react";
const Footer = () => {
    return(
        <div className="hustle-bot__footer d-flex align-items-center justify-content-center">
            <svg width="172" height="21" viewBox="0 0 172 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M128.753 5.59588V8.66838H119.871V5.59588H128.753ZM121.887 2.0625H125.978V15.812C125.978 16.1896 126.035 16.4841 126.151 16.6953C126.266 16.9001 126.426 17.0442 126.631 17.1274C126.842 17.2106 127.085 17.2522 127.36 17.2522C127.552 17.2522 127.744 17.2362 127.936 17.2042C128.128 17.1658 128.276 17.137 128.378 17.1178L129.021 20.1615C128.817 20.2255 128.529 20.2991 128.157 20.3823C127.786 20.4719 127.335 20.5263 126.803 20.5455C125.818 20.5839 124.954 20.4527 124.211 20.1519C123.475 19.851 122.902 19.3837 122.492 18.75C122.083 18.1163 121.881 17.3162 121.887 16.3496V2.0625Z" fill="white"/>
                <path d="M110.989 20.6343C109.498 20.6343 108.208 20.3175 107.12 19.6838C106.038 19.0437 105.203 18.1539 104.614 17.0146C104.025 15.8688 103.73 14.5405 103.73 13.0299C103.73 11.5064 104.025 10.175 104.614 9.03564C105.203 7.88986 106.038 7.00011 107.12 6.36641C108.208 5.7263 109.498 5.40625 110.989 5.40625C112.481 5.40625 113.767 5.7263 114.849 6.36641C115.937 7.00011 116.776 7.88986 117.365 9.03564C117.954 10.175 118.248 11.5064 118.248 13.0299C118.248 14.5405 117.954 15.8688 117.365 17.0146C116.776 18.1539 115.937 19.0437 114.849 19.6838C113.767 20.3175 112.481 20.6343 110.989 20.6343ZM111.008 17.4658C111.687 17.4658 112.253 17.2738 112.708 16.8897C113.162 16.4993 113.505 15.968 113.735 15.2959C113.972 14.6238 114.091 13.8588 114.091 13.0011C114.091 12.1434 113.972 11.3784 113.735 10.7063C113.505 10.0342 113.162 9.50292 112.708 9.11246C112.253 8.72199 111.687 8.52676 111.008 8.52676C110.324 8.52676 109.747 8.72199 109.28 9.11246C108.819 9.50292 108.47 10.0342 108.234 10.7063C108.003 11.3784 107.888 12.1434 107.888 13.0011C107.888 13.8588 108.003 14.6238 108.234 15.2959C108.47 15.968 108.819 16.4993 109.28 16.8897C109.747 17.2738 110.324 17.4658 111.008 17.4658Z" fill="white"/>
                <path d="M86.5273 20.3476V0.683594H94.4006C95.8473 0.683594 97.0539 0.898029 98.0204 1.3269C98.987 1.75577 99.7135 2.35107 100.2 3.11279C100.686 3.86812 100.93 4.73866 100.93 5.72442C100.93 6.49255 100.776 7.16786 100.469 7.75035C100.162 8.32645 99.7391 8.80012 99.2014 9.17138C98.6701 9.53624 98.062 9.79549 97.3771 9.94911V10.1411C98.126 10.1731 98.827 10.3844 99.4799 10.7748C100.139 11.1653 100.674 11.7126 101.083 12.4167C101.493 13.1144 101.698 13.9466 101.698 14.9131C101.698 15.9565 101.439 16.8878 100.92 17.7072C100.408 18.5201 99.6495 19.1634 98.6445 19.6371C97.6396 20.1108 96.401 20.3476 94.9287 20.3476H86.5273ZM90.6848 16.9487H94.0742C95.2328 16.9487 96.0777 16.7278 96.609 16.2861C97.1403 15.8381 97.4059 15.2428 97.4059 14.5003C97.4059 13.9562 97.2747 13.4761 97.0123 13.06C96.7498 12.644 96.3754 12.3175 95.8889 12.0807C95.4088 11.8438 94.8359 11.7254 94.1702 11.7254H90.6848V16.9487ZM90.6848 8.91214H93.7669C94.3366 8.91214 94.8423 8.81293 95.284 8.61449C95.732 8.40966 96.0841 8.12161 96.3401 7.75035C96.6026 7.37909 96.7338 6.93422 96.7338 6.41573C96.7338 5.70522 96.481 5.13232 95.9753 4.69705C95.476 4.26178 94.7655 4.04414 93.8437 4.04414H90.6848V8.91214Z" fill="white"/>
                <path d="M77.0117 20.6343C75.4947 20.6343 74.1888 20.3271 73.0943 19.7126C72.0061 19.0917 71.1676 18.2147 70.5787 17.0818C69.9898 15.9424 69.6953 14.595 69.6953 13.0395C69.6953 11.5225 69.9898 10.191 70.5787 9.04525C71.1676 7.89946 71.9965 7.00651 73.0655 6.36641C74.1408 5.7263 75.4018 5.40625 76.8485 5.40625C77.8214 5.40625 78.7272 5.56308 79.5657 5.87673C80.4107 6.18398 81.1468 6.64805 81.7741 7.26895C82.4078 7.88986 82.9007 8.67078 83.2527 9.61174C83.6048 10.5463 83.7808 11.6409 83.7808 12.8955V14.0189H71.3276V11.484H79.9306C79.9306 10.8951 79.8026 10.3735 79.5465 9.91899C79.2905 9.46451 78.9352 9.10926 78.4808 8.85321C78.0327 8.59077 77.511 8.45955 76.9157 8.45955C76.2948 8.45955 75.7443 8.60357 75.2642 8.89162C74.7905 9.17327 74.4193 9.55413 74.1504 10.0342C73.8816 10.5079 73.744 11.036 73.7376 11.6185V14.0285C73.7376 14.7582 73.872 15.3887 74.1408 15.92C74.4161 16.4513 74.8033 16.8609 75.3026 17.149C75.8019 17.437 76.394 17.581 77.0789 17.581C77.5334 17.581 77.9495 17.517 78.3271 17.389C78.7048 17.261 79.028 17.069 79.2969 16.8129C79.5657 16.5569 79.7706 16.2432 79.9114 15.872L83.6944 16.1216C83.5024 17.0306 83.1087 17.8243 82.5134 18.5028C81.9245 19.1749 81.1628 19.6998 80.2282 20.0775C79.3001 20.4487 78.2279 20.6343 77.0117 20.6343Z" fill="white"/>
                <path d="M67.0121 0.683594V20.3476H62.9219V0.683594H67.0121Z" fill="white"/>
                <path d="M59.944 5.59588V8.66838H51.0625V5.59588H59.944ZM53.0788 2.0625H57.1691V15.812C57.1691 16.1896 57.2267 16.4841 57.3419 16.6953C57.4571 16.9001 57.6172 17.0442 57.822 17.1274C58.0332 17.2106 58.2765 17.2522 58.5517 17.2522C58.7438 17.2522 58.9358 17.2362 59.1278 17.2042C59.3199 17.1658 59.4671 17.137 59.5695 17.1178L60.2128 20.1615C60.008 20.2255 59.7199 20.2991 59.3487 20.3823C58.9774 20.4719 58.5261 20.5263 57.9948 20.5455C57.0091 20.5839 56.1449 20.4527 55.4024 20.1519C54.6663 19.851 54.0934 19.3837 53.6837 18.75C53.2741 18.1163 53.0724 17.3162 53.0788 16.3496V2.0625Z" fill="white"/>
                <path d="M49.1854 9.80377L45.4408 10.0342C45.3768 9.71415 45.2392 9.42611 45.028 9.17007C44.8167 8.90762 44.5383 8.69959 44.1926 8.54596C43.8534 8.38594 43.4469 8.30592 42.9732 8.30592C42.3395 8.30592 41.805 8.44035 41.3698 8.70919C40.9345 8.97163 40.7169 9.32369 40.7169 9.76536C40.7169 10.1174 40.8577 10.4151 41.1393 10.6583C41.421 10.9015 41.9042 11.0968 42.5892 11.244L45.2584 11.7817C46.6922 12.0761 47.7612 12.5498 48.4653 13.2027C49.1694 13.8556 49.5215 14.7134 49.5215 15.7759C49.5215 16.7425 49.2366 17.5906 48.667 18.3204C48.1037 19.0501 47.3291 19.6198 46.3434 20.0294C45.364 20.4327 44.2342 20.6343 42.954 20.6343C41.0017 20.6343 39.4462 20.2279 38.2877 19.4149C37.1355 18.5956 36.4602 17.4818 36.2617 16.0736L40.2848 15.8624C40.4064 16.4577 40.7008 16.9121 41.1681 17.2258C41.6354 17.533 42.2339 17.6867 42.9636 17.6867C43.6805 17.6867 44.2566 17.549 44.6919 17.2738C45.1336 16.9921 45.3576 16.6305 45.364 16.1888C45.3576 15.8176 45.2008 15.5135 44.8935 15.2767C44.5863 15.0334 44.1126 14.8478 43.4725 14.7198L40.9185 14.2109C39.4782 13.9228 38.4061 13.4236 37.702 12.713C37.0042 12.0025 36.6554 11.0968 36.6554 9.9958C36.6554 9.04845 36.9114 8.23231 37.4235 7.5474C37.942 6.86249 38.6685 6.3344 39.6031 5.96314C40.544 5.59188 41.645 5.40625 42.906 5.40625C44.7687 5.40625 46.2346 5.79991 47.3035 6.58724C48.3789 7.37457 49.0062 8.44675 49.1854 9.80377Z" fill="white"/>
                <path d="M29.5513 14.0702V5.60156H33.6416V20.3496H29.7145V17.6707H29.5609C29.228 18.5349 28.6744 19.2294 27.8998 19.7543C27.1317 20.2792 26.1939 20.5416 25.0866 20.5416C24.1008 20.5416 23.2335 20.3176 22.4845 19.8695C21.7356 19.4214 21.1499 18.7845 20.7275 17.9588C20.3114 17.1331 20.1002 16.1441 20.0938 14.9919V5.60156H24.184V14.2622C24.1904 15.1327 24.4241 15.8208 24.8849 16.3265C25.3458 16.8322 25.9635 17.085 26.738 17.085C27.2309 17.085 27.6918 16.973 28.1207 16.749C28.5495 16.5186 28.8952 16.1793 29.1576 15.7312C29.4265 15.2831 29.5577 14.7295 29.5513 14.0702Z" fill="white"/>
                <path d="M0 20.3476V0.683594H4.15748V8.79692H12.5973V0.683594H16.7451V20.3476H12.5973V12.2247H4.15748V20.3476H0Z" fill="white"/>
                <circle cx="138.113" cy="10.2891" r="3.21881" fill="white"/>
                <path d="M171.995 0V19.8383H166.609V0H171.995Z" fill="white"/>
                <path d="M150.054 19.8383H144.242L150.79 0H158.152L164.7 19.8383H158.888L154.549 5.46328H154.394L150.054 19.8383ZM148.969 12.0115H159.896V16.0411H148.969V12.0115Z" fill="white"/>
            </svg>
        </div>
    )
}

export default Footer;