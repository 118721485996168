export const CHANGE_CAMPAIGN_STATUS = 'CHANGE_CAMPAIGN_STATUS';
export const SET_CAMPAIGN_DATA = 'SET_CAMPAIGN_DATA';
export const SET_FORECASTED_REACH = 'SET_FORECASTED_REACH';
export const SET_CAMPAIGN_LOADER = 'SET_CAMPAIGN_LOADER';
export const SET_TARGET_AUDIENCE = 'SET_TARGET_AUDIENCE';
export const SET_SAMPLED_PROSPECTS = 'SET_SAMPLED_PROSPECTS';
export const SET_CREATIVE_ATTACHMENTS = 'SET_CREATIVE_ATTACHMENTS';
export const SET_AUDIENCE_ATTACHMENTS = 'SET_AUDIENCE_ATTACHMENTS';
export const SET_INCOMPLETE_STATUS = 'SET_INCOMPLETE_STATUS';
export const UPDATE_AUDIENCE_SIZE = 'UPDATE_AUDIENCE_SIZE';
export const RESET_CAMPAIGN_DATA = 'RESET_CAMPAIGN_DATA';
export const SET_CAMPAIGN_PHASE = 'SET_CAMPAIGN_PHASE';
export const SET_EDIT_MODE = 'SET_EDIT_MODE';
export const SET_DRAFT_CAMPAIGN_ID = 'SET_DRAFT_CAMPAIGN_ID';
export const SET_CAMPAIGN_ID = 'SET_CAMPAIGN_ID';
export const SET_EDITED_STEPS = 'SET_EDITED_STEPS';
export const SET_DRAFT_CAMPAIGN_BUDGET = 'SET_DRAFT_CAMPAIGN_BUDGET';
export const SET_DRAFT_CAMPAIGN_BUDGET_TYPE = 'SET_DRAFT_CAMPAIGN_BUDGET_TYPE';
export const SET_DRAFT_CAMPAIGN_SUCCESS = 'SET_DRAFT_CAMPAIGN_SUCCESS';
export const SET_DRAFT_CAMPAIGN_PRICE_POINT = 'SET_DRAFT_CAMPAIGN_PRICE_POINT';
export const SET_DRAFT_CAMPAIGN_COST_PER_LEAD = 'SET_DRAFT_CAMPAIGN_COST_PER_LEAD';
export const SET_DRAFT_CAMPAIGN_SALES_CYCLE_LENGTH = 'SET_DRAFT_CAMPAIGN_SALES_CYCLE_LENGTH';

export const SET_IS_CAMPAIGN_MANAGER_GUEST = 'SET_IS_CAMPAIGN_MANAGER_GUEST';
export const SET_IS_NEW_USER = 'SET_IS_NEW_USER';
export const SET_IS_CAMPAIGN_EDITABLE = 'SET_IS_CAMPAIGN_EDITABLE';
export const SET_ATTACHMENT_CAMPAIGN_ID = 'SET_ATTACHMENT_CAMPAIGN_ID';
export const SET_TOGGLE_EDIT_LOADER = 'SET_TOGGLE_EDIT_LOADER';
export const SET_ANALYTICS_FILTER_DATE = 'SET_ANALYTICS_FILTER_DATE';
export const SET_FILTER_CAMPAIGN_IDS = 'SET_FILTER_CAMPAIGN_IDS';
export const SET_IS_CAMPAIGN_MANAGER_GUEST_LOADER = 'SET_IS_CAMPAIGN_MANAGER_GUEST_LOADER';
export const SET_AI_FORM_ANSWERS_LOADING = 'SET_AI_FORM_ANSWERS_LOADING';
export const SET_IS_AI_FORM_ANSWERS = 'SET_IS_AI_FORM_ANSWERS';
export const SET_AI_ANSWERS_LOADING = 'SET_AI_ANSWERS_LOADING';
export const SET_AI_ANSWERS_ERROR = 'SET_AI_ANSWERS_ERROR';
export const SET_HUSTLE_BOT_DATA = 'SET_HUSTLE_BOT_WORKFLOW_STATUS';

// HustleBot
export const SET_HUSTLE_BOT_CAMPAIGN_NAME = 'SET_HUSTLE_BOT_CAMPAIGN_NAME';

export const SET_SCRAPED_CAMPAIGN_DATA = 'SET_SCRAPED_CAMPAIGN_DATA';